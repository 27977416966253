<template>
  <div id="faqs-page">
    <div class="page-header faqs-header row w-100 align-items-end mx-0 mb-4">
      <div class="title col-12 col-lg-8 mx-auto pb-2">
        <h3 class="text-white">{{ $t("telestia.title.faq") }}</h3>
      </div>
    </div>

    <!-- Loading state -->
    <div v-if="pendingComponentInit" class="loading-state d-flex justify-content-center">
      <b-spinner style="width: 3rem; height: 3rem;" type="grow" variant="primary" />
    </div>

    <!-- Loaded state -->
    <template v-else>

      <div v-if="error" class="row mx-0">
        <div class="col-12 col-lg-8 mx-auto">
          <h4 class="mb-2">{{ error }}</h4>
        </div>
      </div>

      <div v-else class="row mx-0">
        <div v-for="(section, sIdx) in Object.values(faqs)" :key="`section-${sIdx}`" class="col-12 col-lg-8 mx-auto">
        <h5 id="section" class="font-weight-bold mt-2 mb-2">{{ section.heading }}</h5>
        <div class="accordion mb-4" role="tablist">
          <b-card v-for="(entry, eIdx) in section.questions" :key="`section-${sIdx}-question-${eIdx}`" no-body>
            <b-card-header header-tag="header" class="bg-white p-2" role="tab">
              <div
                  :class="{ 'font-weight-bold': collapsedAnswerId === `accordion-${sIdx}-${eIdx}` }"
                  class="question mouse-pointer"
                  v-b-toggle="`accordion-${sIdx}-${eIdx}`"
                  @click="toggleCollapsed(`accordion-${sIdx}-${eIdx}`)"
              >
              <span>
                <font-awesome-icon
                    :icon="['fas', collapsedAnswerId === `accordion-${sIdx}-${eIdx}` ? 'caret-down' : 'caret-right']"
                    size="lg"
                    class="text-blue mr-2"
                />{{ entry.question }}
              </span>
              </div>
            </b-card-header>

            <b-collapse :id="`accordion-${sIdx}-${eIdx}`" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-card-text>{{ entry.answer }}</b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
      </div>

    </template>

  </div>
</template>

<script>
export default {
  name: "faq",

  data() {
    return {
      pendingComponentInit: false,

      faqs: {},
      collapsedAnswerId: '',
      error: ''
    }
  },

  created() {
    this.fetchFaqs();
  },

  computed: {
    isOnlineBuild() { return this.$store.getters["app/isOnline"] },
  },

  methods: {
    // Fetch Frequently Asked Questions
    async fetchFaqs() {
      try {
        this.pendingComponentInit = true;
        this.faqs = this.isOnlineBuild
          ? await this.$store.dispatch("fetchFaqs")
          : await this.$store.getters.getFaqs;
      } catch (e) {
        console.log(e);
        this.error = 'FAQs could not be retrieved.. Something went wrong';
      } finally {
        this.pendingComponentInit = false;
      }
    },

    // Toggle FAQs accordion entry open state
    toggleCollapsed(id) {
      this.collapsedAnswerId = this.collapsedAnswerId === id ? '' : id
    }
  }
}
</script>

<style scoped>
.question:hover {
  color: #1b96cf;
}
.question:focus {
  outline: none;
}
.faqs-header {
  background-image: url(../../assets/images/page-header/faq.jpg);
}
</style>

